// Hydro web specific scripts

import { Application } from "@hotwired/stimulus";
import { registerControllers } from "stimulus-vite-helpers";

const application = Application.start();
// application.debug = true
window.Stimulus = application;

const regularControllers = import.meta.glob(
  "../controllers/hydro_stations_plots_controller.js",
  { eager: true }
);
registerControllers(application, regularControllers);
registerControllers(
  application,
  import.meta.glob("../controllers/dropdown_downloads_controller.js", {
    eager: true,
  })
);

const viewComponentController = import.meta.glob(
  "../../components/web/hydro/**/*_controller.js",
  { eager: true }
);
registerControllers(
  application,
  import.meta.glob("../controllers/hydro_favorite_toggle_controller.js", {
    eager: true,
  })
);
registerControllers(application, viewComponentController);

// enabling global turbo drive breaks styleguide plugins
// import { Turbo  } from "@hotwired/turbo-rails"
// Turbo.session.drive = false;

// we have to reinitialize plugins on frame-load to make tabesort work
// document.addEventListener("turbo:frame-load", function() {
//   console.log("turbo:frame-load");
//   $('.table-sort').tablesorter({ sortLocaleCompare : true });
// })

// somehow
// document.addEventListener("turbo:load", function() {
//   console.log("turbo:load");
// })
